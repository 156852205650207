import React, { Component } from "react";
import Card from "./CardsUi";
import img1 from "../assets/twitter_bot.png";
import img2 from "../assets/pika.jpg";
import img3 from "../assets/img-pet-adoption-101.jpg";
import img4 from "../assets/bernie-sanders-asking-for-your-support-is-a-meme-for-the-ent_an3s.620.jpg";
import img5 from "../assets/swift-og.png";

class Cards extends Component {
  render() {
    return (
      <div className="container-fluid d-flex justify-content-center">
        <div className="row">
          <div className="col-md-auto">
            <Card
              imgsrc={img1}
              title="Twitter Bot "
              par="This was a Twitter bot that I made that uses Twitter's api and wrote about on medium. The project was published by Chatbots Life. "
              link="https://github.com/njread/Twitter_bot"
            />
          </div>
          <div className="col-md-auto">
            <Card
              imgsrc={img2}
              title="Pokemon Optimization"
              par="A fun project I did to show an optimal portfolio of Pokemon in the classic Nintendo game using data science concepts."
              link="https://github.com/njread/Pokemon"
            />
          </div>
          <div className="col-md-auto">
            <Card
              imgsrc={img3}
              title="Animal Adoption Website"
              par="This was a project where I made a animal adoption website in React. This app calls an API with the animal info."
              link="https://github.com/njread/Animal_adoptoin_website"
            />
          </div>
          <div className="col-md-auto">
            <Card
              imgsrc={img4}
              title="Survival analysis of Primary Candidates"
              par="Used Survival analysis to construct a model to predict if a candidate’s campaign will be successful."
              link="https://github.com/njread/Survival_Analysis_Capstone"
            />
          </div>
          <div className="col-md-auto">
            <Card
              imgsrc={img5}
              title="iOS Calculator Using Swift and UI kit"
              par="Playing around with Swift to learn more about creating mobile applications by making a tip calculator."
              link="https://github.com/njread/iosCalculator"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Cards;
