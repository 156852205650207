import React from "react";
import "./title-style.css";
import img_twt from "../assets/iconfinder_1_Twitter_colored_svg_5296514.png";
import img_link from "../assets/iconfinder_1_Linkedin_unofficial_colored_svg_5296501.png";
import img_med from "../assets/iconfinder_medium_social_network_logo_1920529.png";
const Title = (props) => {
  return (
    <nav className="navbar navbar-dark bg-dark">
      <div className="container-fluid">
        <h1 className="navbar-brand">Nicks Portfolio!</h1>

        <form className="d-flex">
          <a href="https://medium.com/@Nicholas_J_Read">
            <img src={img_med} alt="medium_logo" />
          </a>
          <a href="https://twitter.com/Nicholas_J_Read">
            <img src={img_twt} alt="twitter_logo" />
          </a>
          <a href="https://www.linkedin.com/in/nicholas-read-ds/">
            <img src={img_link} alt="linkedin_logo" />
          </a>
        </form>
      </div>
    </nav>
  );
};

export default Title;
