import React from "react";
import "./card-style.css";
const Card = (props) => {
  return (
    <div className="col-lg-3">
      <div className="card d-flex flex-column text-center">
        <div className="overflow">
          <img src={props.imgsrc} alt="project" />
        </div>
        <div className="card-body text-dark">
          <h4 className="card-title">{props.title}</h4>
          <p className="card-text">{props.par}</p>
          <a href={props.link} className="btn btn-outline-success">
            link to project
          </a>
        </div>
      </div>
    </div>
  );
};
export default Card;
