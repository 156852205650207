import React from "react";
import "./footer-style.css";
import resume from "../assets/Nicholas_Read_Resumé-.pdf";
const Footer = (props) => {
  return (
    <footer className="text-muted py5">
      <div className="container">
        <h1>Thank you for checking this out</h1>
        <p className="float-end">
          If you have any questions about the projects worked on please feel
          free to reach out to me on{" "}
          <a href="https://twitter.com/Nicholas_J_Read">twitter</a> or
          <a href="https://github.com/njread"> github.com</a>. If you would like
          to work together please{" "}
          <a href={resume} download>
            click here
          </a>{" "}
          to check out my resume
        </p>
        <p>This page was built out using React by the author: Nicholas Read</p>
      </div>
    </footer>
  );
};
export default Footer;
